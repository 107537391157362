function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function sum(collection) {
    var total = 0;
    for (let key in collection) {
        total += collection[key];
    }
    return total;
}

if (!Array.prototype.reduce) {
    Array.prototype.reduce = function (callback /*, initialValue*/) {
        'use strict';
        if (this == null) {
            throw new TypeError('Array.prototype.reduce called on null or undefined');
        }
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }
        var t = Object(this), len = t.length >>> 0, k = 0, value;
        if (arguments.length == 2) {
            value = arguments[1];
        } else {
            while (k < len && !(k in t)) {
                k++;
            }
            if (k >= len) {
                throw new TypeError('Reduce of empty array with no initial value');
            }
            value = t[k++];
        }
        for (; k < len; k++) {
            if (k in t) {
                value = callback(value, t[k], k, t);
            }
        }
        return value;
    };
}

jQuery(document).ready(function () {
    var height = 0;
    var informatiezuilItem = jQuery('.informatiezuil__item');
    informatiezuilItem.each(function () {
        if ($(this).height() > height) {
            height = $(this).height()
        }
    });
    informatiezuilItem.height(height);
});

jQuery(document).ready(function ($) {
    var id = undefined;
    var filledIn = Cookies.get(window.location.pathname) !== undefined;
    var text = $('#transactionele-survey__text');
    var thanks = $('#transactionele-survey__text--thanks');
    var no = $('#transactionele-survey__text--no');
    var buttonYes = $('#transactionele-survey__button--yes');
    var buttonNo = $('#transactionele-survey__button--no');
    var form = $('#transactionele-survey__form');

    if (filledIn) {
        text.hide();
        buttonNo.hide();
        buttonYes.hide();
        thanks.show();
    }

    buttonYes.click(function () {
        text.fadeOut();
        buttonNo.fadeOut();
        buttonYes.fadeOut(function () {
            thanks.fadeIn();
        });

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: {
                action: 'ta_feedback',
                payload: {
                    slug: window.location.pathname,
                    found: 1
                }
            },
            success: function () {
                Cookies.set(window.location.pathname, '', {expires: 7});
            }
        })
    });

    buttonNo.click(function () {
        text.fadeOut();
        buttonNo.fadeOut();
        buttonYes.fadeOut(function () {
            no.fadeIn();
        });
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: {
                action: 'ta_feedback',
                payload: {
                    slug: window.location.pathname,
                    found: 0
                }
            },
            success: function (response) {
                id = response.id;
                Cookies.set(window.location.pathname, '', {expires: 7});
            }
        })
    });

    form.submit(function () {
        console.log('form');
        event.preventDefault();
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: {
                action: 'ta_add_note',
                id: id,
                note: $('#transactionele-survey__textarea').val()
            },
            success: function () {
                Cookies.set(window.location.pathname, '', {expires: 7});
                no.fadeOut(function(){
                    thanks.fadeIn()
                });
            }
        })
    })
});
