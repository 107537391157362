(function () {

    $('body').on('click', '.accordion__title', function () {
        var $accordionTitle = $(this);
        $accordionTitle.siblings('.accordion__content').slideToggle(200, () => toggleAccordionText($accordionTitle));
    });

    function toggleAccordionText($accordionTitle) {
        if ($accordionTitle.text() === '+ Minder informatie') {
            return $accordionTitle.text('+ Meer informatie');
        }

        $accordionTitle.text('+ Minder informatie');
    }

})();