angular.module('app', ['ngRoute', 'testService']);

//     .controller('TestController', function (TestService, $location, $log) {
//             $log.debug('TestController init');
//
//             this.user = {
//                 name: '',
//                 email: ''
//             };
//
//             this.startTest = () => {
//                 $log.debug('Start the test');
//                 TestService.setTestStarted();
//
//                 if (TestService.hasTestStarted() === true) {
//                     return $location.path('/vragenlijst');
//                 }
//
//                 // If the test hasn't been started it must've been a reload or something. We'll redirect to the root
//                 return $location.path('');
//             }
//         }
