function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function sum(collection) {
    var total = 0;
    for (let key in collection) {
        total += collection[key];
    }
    return total;
}

if (!Array.prototype.reduce) {
    Array.prototype.reduce = function (callback /*, initialValue*/) {
        'use strict';
        if (this == null) {
            throw new TypeError('Array.prototype.reduce called on null or undefined');
        }
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }
        var t = Object(this), len = t.length >>> 0, k = 0, value;
        if (arguments.length == 2) {
            value = arguments[1];
        } else {
            while (k < len && !(k in t)) {
                k++;
            }
            if (k >= len) {
                throw new TypeError('Reduce of empty array with no initial value');
            }
            value = t[k++];
        }
        for (; k < len; k++) {
            if (k in t) {
                value = callback(value, t[k], k, t);
            }
        }
        return value;
    };
}

jQuery(document).ready(function () {
    var height = 0;
    var informatiezuilItem = jQuery('.informatiezuil__item');
    informatiezuilItem.each(function () {
        if ($(this).height() > height) {
            height = $(this).height()
        }
    });
    informatiezuilItem.height(height);
});

jQuery(document).ready(function ($) {
    var id = undefined;
    var filledIn = Cookies.get(window.location.pathname) !== undefined;
    var text = $('#transactionele-survey__text');
    var thanks = $('#transactionele-survey__text--thanks');
    var no = $('#transactionele-survey__text--no');
    var buttonYes = $('#transactionele-survey__button--yes');
    var buttonNo = $('#transactionele-survey__button--no');
    var form = $('#transactionele-survey__form');

    if (filledIn) {
        text.hide();
        buttonNo.hide();
        buttonYes.hide();
        thanks.show();
    }

    buttonYes.click(function () {
        text.fadeOut();
        buttonNo.fadeOut();
        buttonYes.fadeOut(function () {
            thanks.fadeIn();
        });

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: {
                action: 'ta_feedback',
                payload: {
                    slug: window.location.pathname,
                    found: 1
                }
            },
            success: function () {
                Cookies.set(window.location.pathname, '', {expires: 7});
            }
        })
    });

    buttonNo.click(function () {
        text.fadeOut();
        buttonNo.fadeOut();
        buttonYes.fadeOut(function () {
            no.fadeIn();
        });
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: {
                action: 'ta_feedback',
                payload: {
                    slug: window.location.pathname,
                    found: 0
                }
            },
            success: function (response) {
                id = response.id;
                Cookies.set(window.location.pathname, '', {expires: 7});
            }
        })
    });

    form.submit(function () {
        console.log('form');
        event.preventDefault();
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: {
                action: 'ta_add_note',
                id: id,
                note: $('#transactionele-survey__textarea').val()
            },
            success: function () {
                Cookies.set(window.location.pathname, '', {expires: 7});
                no.fadeOut(function(){
                    thanks.fadeIn()
                });
            }
        })
    })
});

(function () {

    $('body').on('click', '.accordion__title', function () {
        var $accordionTitle = $(this);
        $accordionTitle.siblings('.accordion__content').slideToggle(200, () => toggleAccordionText($accordionTitle));
    });

    function toggleAccordionText($accordionTitle) {
        if ($accordionTitle.text() === '+ Minder informatie') {
            return $accordionTitle.text('+ Meer informatie');
        }

        $accordionTitle.text('+ Minder informatie');
    }

})();
jQuery('.faq__header').click(function () {
    jQuery(this).find('.faq__header__pointer').toggleClass('faq__header__pointer--rotated');
    jQuery(this).siblings('.faq__content').slideToggle();
});

angular.module('apiService', [])

    .service('ApiService', ["$http", "$log", function ($http, $log) {

        let basePath = '/wp-json/wp/v2';

        this.taConcepten = null;

        this.loadTaConcepten = () => {
            $log.debug('loaded ta concepten');
            $http.get(`${basePath}/ta-concepten`);
        };
        /**
         * Check if the test has started or not.
         *
         * @type {boolean}
         */
        this.hasTestStarted = () => this.testHasStarted === true;

        /**
         * Set the test state to started
         */
        this.setTestStarted = () => {
            $log.debug('testHasStarted set to true');
            this.testHasStarted = true;
        };

        /**
         * Submit the user and answers to the server to be processed
         * @param user
         * @param questions
         * @returns {HttpPromise} a promise which contains the results
         */
        this.submitTest = (user, questions) => {
            return $http.post('/api-test.php', {action: 'result', user, questions});
        };

        this.getQuestions = () => {
            return $http.get('/api-test.php?action=get_questions')
        };

        this.getTexts = () => {
            return $http.get('/api-test.php?action=getTexts')
        };

        this.sendMail = (user_id, canvas1, canvas2) => {
            $http({
                url: '/wp-admin/admin-ajax.php',
                method: 'POST',
                data: $.param({
                    action: 'send_result_mail',
                    user_id,
                    canvas1,
                    canvas2
                }),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        };

        this.sendNewsletterNotification = (user_id) => {
            $http({
                url: '/wp-admin/admin-ajax.php',
                method: 'POST',
                data: $.param({
                    action: 'send_newsletter_notification',
                    user_id
                }),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        };
    }]);

angular.module('testService', [])

.service('TestService', ["$log", function ($log) {
    this.state = 'waiting';

    this.hasStarted = false;

    /**
     * Check if the test has started or not.
     *
     * @type {boolean}
     */
    this.hasTestStarted = () => this.testHasStarted === true;

    /**
     * Set the test state to started
     */
    this.setTestStarted = () => {
        $log.debug('testHasStarted set to true');
        this.testHasStarted = true;
    };
}]);


angular.module('app', ['ngRoute', 'testService']);

//     .controller('TestController', function (TestService, $location, $log) {
//             $log.debug('TestController init');
//
//             this.user = {
//                 name: '',
//                 email: ''
//             };
//
//             this.startTest = () => {
//                 $log.debug('Start the test');
//                 TestService.setTestStarted();
//
//                 if (TestService.hasTestStarted() === true) {
//                     return $location.path('/vragenlijst');
//                 }
//
//                 // If the test hasn't been started it must've been a reload or something. We'll redirect to the root
//                 return $location.path('');
//             }
//         }

angular.module('test', ['ngRoute', 'ngAnimate', 'testService', 'apiService'])

    .config(["$routeProvider", function ($routeProvider) {
        $routeProvider
            .when('/vragenlijst', {
                templateUrl: '/wp-content/themes/corael/views/vragenlijst.html',
            })
            .when('/uitslag', {
                templateUrl: '/wp-content/themes/corael/views/result.html',
            })
            .otherwise({
                templateUrl: '/wp-content/themes/corael/views/test.html',
            })
    }])

    .filter('unsafe', ["$sce", function ($sce) {
        return $sce.trustAsHtml;
    }])

    .controller('TestController', ["TestService", "ApiService", "$location", "$sce", "$log", "$scope", "$window", "$rootScope", "$anchorScroll", function (TestService, ApiService, $location, $sce, $log, $scope, $window, $rootScope, $anchorScroll) {
        $log.debug('TestController init');
        this.debug = false;

        if (this.debug) {
            $log.log(`--- NOTE -----------
| Debug mode is ON |
--------------------`);
        }

        this.texts = {};

        ApiService.getTexts().then((result) => {
            angular.forEach(result.data, (value, key) => {
                this.texts[key] = value;
            });
        });

        // Check if the user isn't on a other page than the root
        if ($location.path() !== "/" && this.debug === false) {
            $location.path('/');
        }

        ApiService.getQuestions().then((response) => {
            this.questions = response.data;
            this.questions_count = Object.keys(this.questions).length
        });

        this.user_id = null;
        this.canFinishTest = false;
        this.skippable_questions = 3;
        this.clickedQuestion = [];

        this.result = undefined;

        this.isRemainingQuestionsVisible = true;

        this.user = {
            name: '',
            email: '',
            newsletter: true
        };

        this.startTest = (form) => {
            $log.debug('Start the test');

            if (form.$invalid) {
                alert('Vul alstublieft uw naam en e-mailadres in.');
                return;
            }

            TestService.setTestStarted();

            if (TestService.hasTestStarted() === true) {
                $location.path('/vragenlijst');
                $anchorScroll();
                return
            }

            // If the test hasn't been started it must've been a reload or something. We'll redirect to the root
            return $location.path('');
        };

        this.groupHasActive = (group_id) => {
            let hasActive = false;

            this.questions[group_id].forEach((question)=> {
                if (question.active === 1) {
                    hasActive = true;
                }
            });
            return hasActive;
        };

        this.getLastQuestion = () => this.questions[this.questions_count];

        this.buttonClick = (group_id, question_id, index) => {

            this.clickedQuestion.push(index);

            this.clickedQuestion = this.clickedQuestion.filter(onlyUnique);

            this.questions[group_id].forEach((question)=> {
                question.active = 0;
                if (question.id === question_id) {
                    question.active = 1;
                }
            });

            this.checkUserCanFinishTest();
        };

        this.checkUserCanFinishTest = () => {
            this.canFinishTest = this.clickedQuestion >= 30;
        };

        this.getRemainingQuestions = () => {
            let remaining = (this.questions_count - 3) - this.clickedQuestion.length;

            if (remaining < 0) {
                return 0;
            }
            return remaining;
        };

        this.debugAllFirstQuestions = () => {
            for (let key in this.questions) {
                this.questions[key][0].active = 1;
            }
        };

        this.getClickedQuestions = () => {
            let clickedQuestions = [];
            angular.forEach(this.questions, (questionRow) => {
                angular.forEach(questionRow, (question) => {
                    if (question.active === 1) {
                        clickedQuestions.push(question.id);
                    }
                })
            });
            return clickedQuestions;
        };

        this.showRemainingQuestions = () => {
            this.isRemainingQuestionsVisible = true;
        };

        this.finishTest = () => {
            // if (this.canFinishTest) {
            let clickedQuestions = this.getClickedQuestions();
            ApiService.submitTest(this.user, clickedQuestions).then((response) => {
                this.user_id = response.data.user_id;
                this.result = response.data;
                this.text1 = $sce.trustAsHtml(this.result.texts.enneagram[0]);
                this.text2 = $sce.trustAsHtml(this.result.texts.enneagram[1]);
                $location.path('/uitslag');
                $anchorScroll();
                $scope.$on('$viewContentLoaded', () => {
                    let options = {
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    display: false
                                }]
                            }
                    };
                    ['drivers', 'ovks'].forEach((value)=> {
                        let labels = [];
                        let values = [];

                        var result = this.result.results[value];
                        angular.forEach(result, (value, key) => {
                            labels.push(key);
                            values.push(value);
                        });

                        let chart = document.getElementById(value);
                        new Chart(chart, {
                            type: 'bar',
                            data: {
                                labels: labels,
                                datasets: [{
                                    data: values,
                                    backgroundColor: '#B5121B',
                                    borderColor: 'black',
                                    borderWidth: 1
                                }]
                            },
                            options
                        });
                    });


                });
                // }

                this.showRemainingQuestions();
                setTimeout(() => {
                    console.log(document.querySelector('#ovks').toDataURL());
                    console.log(document.querySelector('#drivers').toDataURL());
                    ApiService.sendMail(this.user_id, document.querySelector('#ovks').toDataURL(), document.querySelector('#drivers').toDataURL());
                    if (this.user.newsletter) {
                        ApiService.sendNewsletterNotification(this.user_id);
                    }
                }, 1000)

            });
        };

        this.sum = (collection) => sum(collection);

        this.debugBackToVragenlijst = () => {
            $location.path('/vragenlijst');
        }
    }]);
