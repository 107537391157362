angular.module('apiService', [])

    .service('ApiService', function ($http, $log) {

        let basePath = '/wp-json/wp/v2';

        this.taConcepten = null;

        this.loadTaConcepten = () => {
            $log.debug('loaded ta concepten');
            $http.get(`${basePath}/ta-concepten`);
        };
        /**
         * Check if the test has started or not.
         *
         * @type {boolean}
         */
        this.hasTestStarted = () => this.testHasStarted === true;

        /**
         * Set the test state to started
         */
        this.setTestStarted = () => {
            $log.debug('testHasStarted set to true');
            this.testHasStarted = true;
        };

        /**
         * Submit the user and answers to the server to be processed
         * @param user
         * @param questions
         * @returns {HttpPromise} a promise which contains the results
         */
        this.submitTest = (user, questions) => {
            return $http.post('/api-test.php', {action: 'result', user, questions});
        };

        this.getQuestions = () => {
            return $http.get('/api-test.php?action=get_questions')
        };

        this.getTexts = () => {
            return $http.get('/api-test.php?action=getTexts')
        };

        this.sendMail = (user_id, canvas1, canvas2) => {
            $http({
                url: '/wp-admin/admin-ajax.php',
                method: 'POST',
                data: $.param({
                    action: 'send_result_mail',
                    user_id,
                    canvas1,
                    canvas2
                }),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        };

        this.sendNewsletterNotification = (user_id) => {
            $http({
                url: '/wp-admin/admin-ajax.php',
                method: 'POST',
                data: $.param({
                    action: 'send_newsletter_notification',
                    user_id
                }),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        };
    });
