angular.module('test', ['ngRoute', 'ngAnimate', 'testService', 'apiService'])

    .config(function ($routeProvider) {
        $routeProvider
            .when('/vragenlijst', {
                templateUrl: '/wp-content/themes/corael/views/vragenlijst.html',
            })
            .when('/uitslag', {
                templateUrl: '/wp-content/themes/corael/views/result.html',
            })
            .otherwise({
                templateUrl: '/wp-content/themes/corael/views/test.html',
            })
    })

    .filter('unsafe', function ($sce) {
        return $sce.trustAsHtml;
    })

    .controller('TestController', function (TestService, ApiService, $location, $sce, $log, $scope, $window, $rootScope, $anchorScroll) {
        $log.debug('TestController init');
        this.debug = false;

        if (this.debug) {
            $log.log(`--- NOTE -----------
| Debug mode is ON |
--------------------`);
        }

        this.texts = {};

        ApiService.getTexts().then((result) => {
            angular.forEach(result.data, (value, key) => {
                this.texts[key] = value;
            });
        });

        // Check if the user isn't on a other page than the root
        if ($location.path() !== "/" && this.debug === false) {
            $location.path('/');
        }

        ApiService.getQuestions().then((response) => {
            this.questions = response.data;
            this.questions_count = Object.keys(this.questions).length
        });

        this.user_id = null;
        this.canFinishTest = false;
        this.skippable_questions = 3;
        this.clickedQuestion = [];

        this.result = undefined;

        this.isRemainingQuestionsVisible = true;

        this.user = {
            name: '',
            email: '',
            newsletter: true
        };

        this.startTest = (form) => {
            $log.debug('Start the test');

            if (form.$invalid) {
                alert('Vul alstublieft uw naam en e-mailadres in.');
                return;
            }

            TestService.setTestStarted();

            if (TestService.hasTestStarted() === true) {
                $location.path('/vragenlijst');
                $anchorScroll();
                return
            }

            // If the test hasn't been started it must've been a reload or something. We'll redirect to the root
            return $location.path('');
        };

        this.groupHasActive = (group_id) => {
            let hasActive = false;

            this.questions[group_id].forEach((question)=> {
                if (question.active === 1) {
                    hasActive = true;
                }
            });
            return hasActive;
        };

        this.getLastQuestion = () => this.questions[this.questions_count];

        this.buttonClick = (group_id, question_id, index) => {

            this.clickedQuestion.push(index);

            this.clickedQuestion = this.clickedQuestion.filter(onlyUnique);

            this.questions[group_id].forEach((question)=> {
                question.active = 0;
                if (question.id === question_id) {
                    question.active = 1;
                }
            });

            this.checkUserCanFinishTest();
        };

        this.checkUserCanFinishTest = () => {
            this.canFinishTest = this.clickedQuestion >= 30;
        };

        this.getRemainingQuestions = () => {
            let remaining = (this.questions_count - 3) - this.clickedQuestion.length;

            if (remaining < 0) {
                return 0;
            }
            return remaining;
        };

        this.debugAllFirstQuestions = () => {
            for (let key in this.questions) {
                this.questions[key][0].active = 1;
            }
        };

        this.getClickedQuestions = () => {
            let clickedQuestions = [];
            angular.forEach(this.questions, (questionRow) => {
                angular.forEach(questionRow, (question) => {
                    if (question.active === 1) {
                        clickedQuestions.push(question.id);
                    }
                })
            });
            return clickedQuestions;
        };

        this.showRemainingQuestions = () => {
            this.isRemainingQuestionsVisible = true;
        };

        this.finishTest = () => {
            // if (this.canFinishTest) {
            let clickedQuestions = this.getClickedQuestions();
            ApiService.submitTest(this.user, clickedQuestions).then((response) => {
                this.user_id = response.data.user_id;
                this.result = response.data;
                this.text1 = $sce.trustAsHtml(this.result.texts.enneagram[0]);
                this.text2 = $sce.trustAsHtml(this.result.texts.enneagram[1]);
                $location.path('/uitslag');
                $anchorScroll();
                $scope.$on('$viewContentLoaded', () => {
                    let options = {
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    display: false
                                }]
                            }
                    };
                    ['drivers', 'ovks'].forEach((value)=> {
                        let labels = [];
                        let values = [];

                        var result = this.result.results[value];
                        angular.forEach(result, (value, key) => {
                            labels.push(key);
                            values.push(value);
                        });

                        let chart = document.getElementById(value);
                        new Chart(chart, {
                            type: 'bar',
                            data: {
                                labels: labels,
                                datasets: [{
                                    data: values,
                                    backgroundColor: '#B5121B',
                                    borderColor: 'black',
                                    borderWidth: 1
                                }]
                            },
                            options
                        });
                    });


                });
                // }

                this.showRemainingQuestions();
                setTimeout(() => {
                    console.log(document.querySelector('#ovks').toDataURL());
                    console.log(document.querySelector('#drivers').toDataURL());
                    ApiService.sendMail(this.user_id, document.querySelector('#ovks').toDataURL(), document.querySelector('#drivers').toDataURL());
                    if (this.user.newsletter) {
                        ApiService.sendNewsletterNotification(this.user_id);
                    }
                }, 1000)

            });
        };

        this.sum = (collection) => sum(collection);

        this.debugBackToVragenlijst = () => {
            $location.path('/vragenlijst');
        }
    });
