angular.module('testService', [])

.service('TestService', function ($log) {
    this.state = 'waiting';

    this.hasStarted = false;

    /**
     * Check if the test has started or not.
     *
     * @type {boolean}
     */
    this.hasTestStarted = () => this.testHasStarted === true;

    /**
     * Set the test state to started
     */
    this.setTestStarted = () => {
        $log.debug('testHasStarted set to true');
        this.testHasStarted = true;
    };
});

